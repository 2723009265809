<template>
  <div class="wrapper" >
    <b-icon icon="qrcode" :size="26"></b-icon>
  </div>
</template>


<script>
export default {
};
</script>


<style lang="scss" scoped>
.wrapper {
  border-radius: 8px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  width: 48px;
  height: 48px;
  position: relative;

  &:hover {
    cursor: pointer;
    &:after{
      content: "";
      display: block;
      position: absolute;
      top: -100px;
      width: 78px;
      height: 78px;
      border-radius: 10px;
      background: url("../../assets/download-qr-code.png") no-repeat center/64px,#FFFFFF;
    }
    &:before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      background: white;
      transform: rotate(
              -45deg) translate(50%,-20px);
      left: 50%;
      bottom: 100%;
      border-bottom-left-radius: 3px;

    }
  }
}
</style>
